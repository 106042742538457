<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t('localite.localites') }}
        <el-button type="primary" icon="el-icon-plus" circle @click="handleCreateRegion" />
      </h2>
    </div>
    <div v-if="isEmptyArray(regions)" class="no-location demo-image">
      <div class="block">
        <h3 class="demonstration">{{ $t('localite.nolocalite') }}</h3>
        <img :src="noLocation.url">
      </div>
    </div>
    <el-tabs v-if="!isEmptyArray(regions)" tab-position="left">
      <el-tab-pane v-if="!isEmptyArray(communes) && !isEmptyArray(villages)" :id="'tab-0'" :key="0" :label="$t('village.village')">
        <villages
          :communes-props="communes"
          :villages-props="villages"
        />
      </el-tab-pane>
      <el-tab-pane v-if="!isEmptyArray(communes) && !isEmptyArray(zones)" :id="'tab-1'" :key="1" label="Communes">
        <communes
          :zones-props="zones"
          :communes-props="communes"
        />
      </el-tab-pane>
      <el-tab-pane v-if="!isEmptyArray(regions) && !isEmptyArray(zones)" :id="'tab-2'" :key="2" label="Zones">
        <zones
          :regions-props="regions"
          :zone-props="zones"
        />
      </el-tab-pane>
      <el-tab-pane v-if="!isEmptyArray(regions)" :id="'tab-3'" :key="3" label="Régions">
        <regions
          :regions-props="regions"
        />
      </el-tab-pane>
    </el-tabs>

    <!-- ======= LOCALITE CREATE DRAWER ======= -->
    <el-drawer
      ref="RegionCreationDrawer"
      :title="$t('localite.AddLocalite')"
      :visible.sync="dialogCreateRegion"
      :before-close="handleCloseRegionCreateDraw"
      :loading="regionCreating"
      size="40%"
    >
      <div class="drawer-body">
        <div class="localaites-steps">
          <el-steps align-center :space="300" :active="steps.current" finish-status="success">
            <el-step title="Région" />
            <el-step title="Zone" />
            <el-step title="Commune" />
            <el-step title="Village" />
          </el-steps>
        </div>
        <div class="user-create-form content-inputs">

          <!-- ======= REGION ======= -->
          <div v-show="steps.current==0" class="region-step">
            <div class="select-region">
              <vs-alert shadow class="mb-15">
                <template #title>
                  {{ $t('region.selectRegionTitle') }}
                </template>
                {{ $t('region.selectOrCreateMessage') }}
              </vs-alert>
              <el-select v-model="currentZone.region_id" :disabled="createNewRegion" class="filter-item" :placeholder="$t('region.region')" style="width: 80%;" prop="region_id" @change="gotoNext()">
                <el-option
                  v-for="(region, idex) in regions"
                  :key="idex"
                  :label="region.nom | uppercaseFirst"
                  :value="region.id"
                  :disabled="!region.is_active"
                />
              </el-select>
              <el-button type="primary" :icon="createNewRegion ? 'el-icon-minus' : 'el-icon-plus'" circle @click="createNewRegion=!createNewRegion" />
            </div>
            <div v-show="createNewRegion" class="region-step-form step-form">
              <el-form ref="regionForm" :rules="regionRules" :model="currentRegion" label-position="left">
                <el-form-item :label="$t('region.nom')" prop="nom">
                  <el-input v-model="currentRegion.nom" />
                </el-form-item>
                <el-form-item :label="$t('general.description')" prop="description">
                  <el-input v-model="currentRegion.description" type="textarea" />
                </el-form-item>
                <div style="text-align:right;padding-top:12px">
                  <el-button plain type="danger" @click="createNewRegion=!createNewRegion">
                    {{ $t('permission.cancel') }}
                  </el-button>
                  <el-button type="primary" :loading="regionCreating" @click="createRegion()">
                    {{ regionCreating ? $t('general.saving') : $t('general.save') }}
                  </el-button>
                </div>
              </el-form>
            </div>
          </div>
          <!-- ======= REGION ======= -->

          <!-- ======= ZONE ======= -->
          <div v-show="steps.current==1" class="zone-step">
            <div class="select-zone">
              <el-tag><strong>{{ $t('region.region') }} : </strong>{{ nomsRegions[currentZone.region_id] }}</el-tag>
              <vs-alert shadow class="mb-15">
                <template #title>
                  {{ $t('zone.selectZoneTitle') }}
                </template>
                {{ $t('zone.selectOrCreateMessage') }}
              </vs-alert>
              <el-select v-model="currentCommune.zone_id" :disabled="createNewZone" class="filter-item" :placeholder="$t('zone.zone')" style="width: 80%;" prop="zone_id" @change="gotoNext()">
                <el-option
                  v-for="(zone, idex) in regionZones"
                  :key="idex"
                  :label="zone.nom | uppercaseFirst"
                  :value="zone.id"
                  :disabled="!zone.is_active"
                />
              </el-select>
              <el-button type="primary" :icon="createNewZone ? 'el-icon-minus' : 'el-icon-plus'" circle @click="createNewZone=!createNewZone" />
            </div>
            <div v-show="createNewZone" class="zone-step-form step-form">
              <el-form ref="zoneForm" :rules="zoneRules" :model="currentZone" label-position="left">
                <el-form-item :label="$t('zone.nom')" prop="nom">
                  <el-input v-model="currentZone.nom" />
                </el-form-item>
                <el-form-item :label="$t('general.description')" prop="description">
                  <el-input v-model="currentZone.description" type="textarea" />
                </el-form-item>
                <div style="text-align:right;padding-top:12px">
                  <el-button plain type="danger" @click="createNewZone=!createNewZone">
                    {{ $t('permission.cancel') }}
                  </el-button>
                  <el-button type="primary" :loading="zoneCreating" @click="createZone()">
                    {{ zoneCreating ? $t('general.saving') : $t('general.save') }}
                  </el-button>
                </div>
              </el-form>
            </div>
          </div>
          <!-- ======= FIN ZONE ======= -->

          <!-- ======= COMMUNE ======= -->
          <div v-show="steps.current==2" class="commune-step">
            <el-tag><strong>{{ $t('region.region') }} : </strong>{{ nomsRegions[currentZone.region_id] }}</el-tag>
            <el-tag><strong>{{ $t('zone.zone') }} : </strong>{{ nomsZones[currentCommune.zone_id] }}</el-tag>
            <div class="select-commune">
              <vs-alert shadow class="mb-15">
                <template #title>
                  {{ $t('commune.selectCommuneTitle') }}
                </template>
                {{ $t('commune.selectOrCreateMessage') }}
              </vs-alert>
              <el-select v-model="currentVillage.commune_id" :disabled="createNewCommune" class="filter-item" :placeholder="$t('commune.commune')" style="width: 80%;" prop="commune_id" @change="gotoNext()">
                <el-option
                  v-for="(commune, idex) in zoneCommunes"
                  :key="idex"
                  :label="commune.nom | uppercaseFirst"
                  :value="commune.id"
                  :disabled="!commune.is_active"
                />
              </el-select>
              <el-button type="primary" :icon="createNewCommune ? 'el-icon-minus' : 'el-icon-plus'" circle @click="createNewCommune=!createNewCommune" />
            </div>
            <div v-show="createNewCommune" class="commune-step-form step-form">
              <el-form ref="communeForm" :rules="communeRules" :model="currentCommune" label-position="left">
                <el-form-item :label="$t('commune.nom')" prop="nom">
                  <el-input v-model="currentCommune.nom" />
                </el-form-item>
                <el-form-item :label="$t('general.description')" prop="description">
                  <el-input v-model="currentCommune.description" type="textarea" />
                </el-form-item>
                <div style="text-align:right;padding-top:12px">
                  <el-button plain type="danger" @click="createNewCommune=!createNewCommune">
                    {{ $t('permission.cancel') }}
                  </el-button>
                  <el-button type="primary" :loading="communeCreating" @click="createCommune()">
                    {{ communeCreating ? $t('general.saving') : $t('general.save') }}
                  </el-button>
                </div>
              </el-form>
            </div>
          </div>
          <!-- ======= FIN COMMUNE ======= -->

          <!-- ======= VILLAGE ======= -->
          <div v-show="steps.current==3" class="village-step">
            <el-tag><strong>{{ $t('region.region') }} : </strong>{{ nomsRegions[currentZone.region_id] }}</el-tag>
            <el-tag><strong>{{ $t('zone.zone') }} : </strong>{{ nomsZones[currentCommune.zone_id] }}</el-tag>
            <el-tag><strong>{{ $t('commune.commune') }} : </strong>{{ nomsCommunes[currentVillage.commune_id] }}</el-tag>
            <div class="village-step-form step-form">
              <el-form ref="villageForm" :rules="villageRules" :model="currentVillage" label-position="left">
                <el-form-item :label="$t('village.nom')" prop="nom">
                  <el-input v-model="currentVillage.nom" />
                </el-form-item>
                <el-form-item :label="$t('general.description')" prop="description">
                  <el-input v-model="currentVillage.description" type="textarea" />
                </el-form-item>
                <div style="text-align:right;padding-top:12px">
                  <el-button plain type="danger" @click="cancelRegionForm">
                    {{ $t('permission.cancel') }}
                  </el-button>
                  <el-button type="primary" :loading="villageCreating" @click="createVillage()">
                    {{ villageCreating ? $t('general.saving') : $t('general.save') }}
                  </el-button>
                </div>
              </el-form>
            </div>
          </div>
          <!-- ======= FIN VILLAGE ======= -->

          <div class="controls">
            <hr>
            <el-button-group>
              <el-button type="secondary" icon="el-icon-arrow-left" @click="goBack()" />
              <el-button type="primary" :icon="steps.current == 3 ? 'el-icon-close' : 'el-icon-arrow-right'" @click="steps.current == 3 ? cancelRegionForm() : gotoNext()" />
            </el-button-group>
            <el-alert
              v-show="steps.hasError"
              :title="steps.errorTitle"
              type="error"
              :description="steps.errorMessage"
              show-icon
            />
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- ======= LOCALITE CREATE DRAWER ======= -->

  </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles

const regionResource = new Resource('regions');
const zoneResource = new Resource('zones');
const communeResource = new Resource('communes');
const villageResource = new Resource('villages');
export default {
  name: 'Localites',
  directives: { waves, permission, role, Pagination },
  data() {
    return {
      noLocation: {
        ok: true,
        url: 'images/nolocation.png',
      },
      steps: {
        current: 0,
        hasError: false,
        errorTitle: this.$t('general.error'),
        errorMessage: '',
      },
      // REGION
      currentRegion: {},
      createNewRegion: false,
      dialogCreateRegion: false,
      regionCreating: false,
      regions: [],
      nomsRegions: {},
      list: null,
      total: 0,
      // FIN REGION

      // ZONE
      currentZone: {},
      zones: [],
      createNewZone: false,
      zoneCreating: false,
      nomsZones: {},
      regionZones: {},
      // FIN ZONE

      // COMMUNE
      currentCommune: {},
      communes: [],
      createNewCommune: false,
      communeCreating: false,
      nomsCommunes: {},
      zoneCommunes: {},
      // FIN COMMUNE

      // VILLAGE
      currentVillage: {},
      villageCreating: false,
      villages: [],
      // FIN VILLAGE

      query: {
        page: 1,
        limit: 100,
        keyword: '',
      },
      regionQuery: {
        page: 1,
        limit: 10000,
        keyword: '',
      },
      regionRules: {
        nom: [{ required: true, message: this.$t('region.NomRequired'), trigger: 'blur' }],
        // description: [{ required: true, message: this.$t('region.DescriptionRequired'), trigger: 'blur' }],
      },
      zoneRules: {
        nom: [{ required: true, message: this.$t('zone.NomRequired'), trigger: 'blur' }],
        // description: [{ required: true, message: this.$t('zone.DescriptionRequired'), trigger: 'blur' }],
      },
      communeRules: {
        nom: [{ required: true, message: this.$t('commune.NomRequired'), trigger: 'blur' }],
        // description: [{ required: true, message: this.$t('commune.DescriptionRequired'), trigger: 'blur' }],
      },
      villageRules: {
        nom: [{ required: true, message: this.$t('village.NomRequired'), trigger: 'blur' }],
        // description: [{ required: true, message: this.$t('village.DescriptionRequired'), trigger: 'blur' }],
      },
    };
  },
  computed: {
  },
  created() {
    this.getZonesList();
    this.getRegionsList();
    this.getCommunesList();
    this.getVillagesList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array){
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    gotoNext(){
      if (this.steps.current === 0){
        if (this.currentZone.region_id){
          this.steps.hasError = false;
          this.getRegionZones(this.currentZone.region_id);
          this.steps.current = 1;
        } else {
          this.$message({
            message: this.$t('zone.RegionRequired'),
            type: 'error',
            duration: 5 * 1000,
          });
          this.steps.errorMessage = this.$t('zone.RegionRequired');
          this.steps.hasError = true;
        }
      } else if (this.steps.current === 1){
        if (this.currentCommune.zone_id){
          this.steps.hasError = false;
          this.getZoneCommunes(this.currentCommune.zone_id);
          this.steps.current = 2;
        } else {
          this.$message({
            message: this.$t('commune.ZoneRequired'),
            type: 'error',
            duration: 5 * 1000,
          });
          this.steps.errorMessage = this.$t('commune.ZoneRequired');
          this.steps.hasError = true;
        }
      } else if (this.steps.current === 2){
        if (this.currentVillage.commune_id){
          this.steps.hasError = false;
          this.steps.current = 3;
        } else {
          this.$message({
            message: this.$t('village.CommuneRequired'),
            type: 'error',
            duration: 5 * 1000,
          });
          this.steps.errorMessage = this.$t('village.CommuneRequired');
          this.steps.hasError = true;
        }
      }
    },
    goBack(){
      if (this.steps.current > 0){
        this.steps.current -= 1;
      }
    },
    async getRegionsList() {
      const { data } = await regionResource.sublist('?typeApi="all"', this.regionQuery);
      this.regions = data;
      const _reg = [];
      data.forEach(region => {
        _reg[region.id] = region.nom;
      });
      this.nomsRegions = _reg;
    },
    async getZonesList() {
      const { data } = await zoneResource.sublist('?typeApi="all"', this.query);
      this.zones = data;
      const _reg = [];
      data.forEach(zone => {
        _reg[zone.id] = zone.nom;
      });
      this.nomsZones = _reg;
    },
    async getCommunesList() {
      const { data } = await communeResource.sublist('?typeApi="all"', this.query);
      this.communes = data;
      const _reg = [];
      data.forEach(commune => {
        _reg[commune.id] = commune.nom;
      });
      this.nomsCommunes = _reg;
    },
    async getVillagesList() {
      const { data, meta } = await villageResource.sublist('?typeApi="all"', this.query);
      this.list = data;
      this.total = meta !== undefined ? meta.total : 0;
      this.villages = data;
    },
    getRegionZones(region_id){
      this.regionZones = [];
      this.zones.forEach(zone => {
        if (zone.region_id === region_id && !this.regionZones.includes(zone)){
          this.regionZones.push(zone);
        }
      });
    },
    getZoneCommunes(zone_id){
      this.zoneCommunes = [];
      this.communes.forEach(commune => {
        if (commune.zone_id === zone_id && !this.zoneCommunes.includes(commune)){
          this.zoneCommunes.push(commune);
        }
      });
    },
    cancelRegionForm() {
      this.steps.current === 0;
      this.$refs.RegionCreationDrawer.closeDrawer();
    },
    handleCloseRegionCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    handleCreateRegion() {
      this.resetNewRegion();
      this.dialogCreateRegion = true;
      this.$nextTick(() => {
        this.$refs['regionForm'].clearValidate();
      });
    },
    resetNewRegion() {
      this.currentRegion = {
        nom: '',
        description: '',
      };
    },
    resetNewZone() {
      this.currentZone.nom = '';
      this.currentZone.description = '';
    },
    resetNewCommune() {
      this.currentCommune.nom = '';
      this.currentCommune.description = '';
    },
    resetNewVillage() {
      this.currentVillage.nom = '';
      this.currentVillage.description = '';
    },
    async createRegion() {
      this.$refs['regionForm'].validate((valid) => {
        if (valid) {
          this.regionCreating = true;
          regionResource
            .store(this.currentRegion)
            .then(async(response) => {
              this.$message({
                message: this.$t('region.NewRegion') + ' ' + this.currentRegion.nom + ' ' + this.$t('region.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              await this.getRegionsList();
              this.resetNewRegion();
              this.currentZone.region_id = response.data.id;
              this.gotoNext();
            })
            .finally(() => {
              this.regionCreating = false;
              this.createNewRegion = false;
            });
        } else {
          return false;
        }
      });
    },
    async createZone() {
      this.$refs['zoneForm'].validate((valid) => {
        if (valid) {
          this.zoneCreating = true;
          zoneResource
            .store(this.currentZone)
            .then(async(response) => {
              this.$message({
                message: this.$t('zone.NewZone') + ' ' + this.currentZone.nom + ' ' + this.$t('zone.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              await this.getZonesList();
              this.resetNewZone();
              this.getRegionZones(this.currentZone.region_id);
              this.currentCommune.zone_id = response.data.id;
              this.gotoNext();
            })
            .finally(() => {
              this.zoneCreating = false;
              this.createNewZone = false;
            });
        } else {
          return false;
        }
      });
    },
    async createCommune() {
      this.$refs['communeForm'].validate((valid) => {
        if (valid) {
          this.communeCreating = true;
          communeResource
            .store(this.currentCommune)
            .then(async(response) => {
              this.$message({
                message: this.$t('commune.NewCommune') + ' ' + this.currentCommune.nom + ' ' + this.$t('commune.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              await this.getCommunesList();
              this.resetNewCommune();
              this.getZoneCommunes(this.currentCommune.zone_id);
              this.currentVillage.commune_id = response.data.id;
              this.gotoNext();
            })
            .finally(() => {
              this.communeCreating = false;
              this.createNewCommune = false;
            });
        } else {
          return false;
        }
      });
    },
    async createVillage() {
      this.$refs['villageForm'].validate((valid) => {
        if (valid) {
          this.villageCreating = true;
          villageResource
            .store(this.currentVillage)
            .then(async(response) => {
              this.$message({
                message: this.$t('village.NewVillage') + ' ' + this.currentVillage.nom + ' ' + this.$t('village.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              await this.getVillagesList();
              this.resetNewVillage();
            })
            .finally(() => {
              this.villageCreating = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
